import { render, staticRenderFns } from "./InvoiceTemplatesList.vue?vue&type=template&id=4e52a95e&scoped=true&"
import script from "./InvoiceTemplatesList.vue?vue&type=script&lang=js&"
export * from "./InvoiceTemplatesList.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceTemplatesList.vue?vue&type=style&index=0&id=4e52a95e&lang=scss&scoped=true&"
import style1 from "./InvoiceTemplatesList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e52a95e",
  null
  
)

export default component.exports